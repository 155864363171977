
import { Vue, Component } from "vue-property-decorator";
import authModule from "@/store/modules/auth";
@Component({
  name: "Header",
})
export default class Header extends Vue {
  private avatar = "https://joeschmoe.io/api/v1/random";

  get username() {
    return this.$Cookies.get("vue_admin_token");
  }

  handleCmd(command: string) {
    if (command === "home") this.$router.push("/home");
    else if (command === "logout") {
      // 注销
      authModule.clearAuth();
      localStorage.removeItem("token");
      this.$Cookies.remove("vue_admin_token");
      this.$router.push(
        `/login?redirect=${encodeURIComponent(this.$route.fullPath)}`
      );
    }
  }

  handleGithub() {
    window.open("https://github.com/szqlovepk/vue-admin");
  }
}
